import React, { useState, memo, useEffect, useRef, useCallback, useMemo } from 'react';
import '../styles/Transcript.css';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import Tooltip from '@mui/material/Tooltip';

const Transcript = memo(
  ({
    alert,
    getPlayerState,
    onTranscriptClick,
    userId,
    inShiftExpanded,
    transcriptUrl,
    defaultShowTranscript = true,
    showTimeline = true,
    setMarkers, // New prop
  }) => {
    const [showTranscript, setShowTranscript] = useState(defaultShowTranscript);
    const [transcripts, setTranscripts] = useState([]);
    const transcriptContainerRef = useRef(null);
    const [activeTranscriptId, setActiveTranscriptId] = useState(null);
    const lastScrolledTranscriptId = useRef(null);
    const [selectedEventType, setSelectedEventType] = useState(null);

    // Events
    const [events, setEvents] = useState([]);

    const isPreEvent = useCallback((item) => {
      return item.relativeTimestamp === undefined || item.relativeTimestamp < 0;
    }, []);

    useEffect(() => {
      if (alert && transcripts.length > 0 && events.length > 0 && setMarkers) {
        const preEventTranscriptsLength = alert.VideoTranscripts ? 0 : (alert.Transcripts || []).length;

        const newMarkers = events
          .filter((event) => event.index >= preEventTranscriptsLength)
          .map((event) => {
            const transcriptIndex = event.index - preEventTranscriptsLength;
            return {
              time: transcripts[transcriptIndex]?.relativeTimestamp || 0,
              label: event.type,
            };
          })
          .filter((marker) => marker.time > 0); // Ensure we only include valid markers

        console.log(
          'events: newMarkers: ',
          newMarkers,
          ' preEventTranscriptsLength: ',
          preEventTranscriptsLength,
          ' alert.Transcripts.length: ',
          (alert.Transcripts || []).length,
          ' alert.VideoTranscripts.length: ',
          (alert.VideoTranscripts || []).length
        );

        setMarkers(newMarkers);
      } else if (alert && alert.RelativeTimestamp !== undefined && alert.Label !== undefined) {
        setMarkers([{ time: alert.RelativeTimestamp, label: alert.Label }]);
      }
    }, [transcripts, events, alert, setMarkers]);

    // Parse VTT file if URL is provided
    const parseVTT = async (url, relativeTimestamp = 0) => {
      try {
        const response = await fetch(url);
        const text = await response.text();
        const lines = text.split('\n');
        const parsedTranscripts = [];
        let index = 0;

        for (let i = 0; i < lines.length; i++) {
          if (lines[i].includes('-->')) {
            const timings = lines[i].split(' --> ');
            const startTime = convertVTTTimeToSeconds(timings[0]);
            const transcript = lines[i + 1];

            parsedTranscripts.push({
              relativeTimestamp: startTime,
              transcript: transcript,
              id: index++,
              isEscalation: Math.round(startTime) === Math.round(relativeTimestamp) ? true : false,
            });
          }
        }

        const offset = alert ? findTranscriptOffset(parsedTranscripts, alert.Transcripts) : 0;

        setTranscripts(parsedTranscripts);

        // Adjust event indices in the timeline
        if (!alert) {
          setEvents([]);
        } else {
          if (alert.Timeline) {
            const adjustedTimeline = alert.Timeline.map((event) => ({
              ...event,
              type: event.type.replace('_', ' '),
              index: event.index + offset,
              endIndex: (event.endIndex || event.index) + offset,
            }));
            setEvents(adjustedTimeline);
            console.log('events: adjustedTimeline: ', adjustedTimeline);
          }
        }

        // console.log('PLYR: parsedTranscripts: ', parsedTranscripts);
      } catch (error) {
        console.error('Error parsing VTT file:', error);
      }
    };

    const convertVTTTimeToSeconds = (vttTime) => {
      const [hours, minutes, seconds] = vttTime.split(':');
      return parseFloat(hours) * 3600 + parseFloat(minutes) * 60 + parseFloat(seconds.replace(',', '.'));
    };

    const findTranscriptOffset = (parsedVTT, alertTranscripts) => {
      if (!alertTranscripts || alertTranscripts.length === 0) return 0;

      for (let i = 0; i < parsedVTT.length - alertTranscripts.length + 1; i++) {
        let match = true;
        for (let j = 0; j < alertTranscripts.length; j++) {
          if (parsedVTT[i + j].transcript.trim() !== alertTranscripts[j].transcript.trim()) {
            match = false;
            break;
          }
        }
        if (match) return i;
      }
      return 0; // If no match found, assume no offset
    };

    // Initialize transcripts based on source (VTT or alert)
    // Inside the useEffect hook where transcripts are initialized
    useEffect(() => {
      if (transcriptUrl) {
        parseVTT(transcriptUrl);
      } else if (alert) {
        const isOverwatch = userId === PLIX_OVERWATCH_ACCOUNT;
        const rawTranscripts = [...(alert.Transcripts || []), ...(alert.VideoTranscripts || [])];
        console.log('PLYR: alert.relativeTimestamp: ', alert);

        if (alert.Label === 'Trigger Phrase') {
          const keyword = alert.Description.replace(/^"(.*)"$/, '$1').toLowerCase();
          let keywordTimeline = [];
          let firstKeywordTimestamp = null;

          rawTranscripts.forEach((transcript, index) => {
            if (transcript.transcript.toLowerCase().includes(keyword)) {
              keywordTimeline.push({
                type: `"${keyword}"`,
                index: index,
              });

              if (
                firstKeywordTimestamp === null &&
                alert.VideoTranscripts &&
                index >= (alert.Transcripts || []).length
              ) {
                firstKeywordTimestamp = transcript.relativeTimestamp;
              }
            }
          });

          if (keywordTimeline.length > 0) {
            alert.Timeline = keywordTimeline;
          }

          if (firstKeywordTimestamp !== null) {
            alert.RelativeTimestamp = firstKeywordTimestamp;
          }

          console.log('alert: keyword: ', keyword, ' keywordTimeline: ', keywordTimeline);
          console.log('alert: ', alert);
        }

        if (!alert.VideoTranscripts) {
          parseVTT(
            `https://plixvideostorage.blob.core.windows.net/transcripts-vtt/${alert.VideoFileName?.replace('.mp4', '.vtt')}?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`,
            alert.RelativeTimestamp ?? 0
          );
        } else {
          setTranscripts(
            rawTranscripts.map((item, index) => ({
              ...item,
              relativeTimestamp: item.relativeTimestamp ?? -1,
              id: index,
            }))
          );
          if (showTimeline && alert.Timeline) {
            setEvents(alert.Timeline.map((event) => ({ ...event, type: event.type.replace('_', ' ') })));
            console.log(
              'events: alert.Timeline: ',
              alert.Timeline.map((event) => ({ ...event, type: event.type.replace('_', ' ') }))
            );
          }
        }
      }
    }, [transcriptUrl, alert, userId, showTimeline]);

    const isTranscriptActive = useCallback((currentItem, nextItem, currentTime) => {
      if (!nextItem) {
        return currentTime >= currentItem.relativeTimestamp;
      }
      return currentTime >= currentItem.relativeTimestamp && currentTime < nextItem.relativeTimestamp;
    }, []);

    const scrollToTranscript = useCallback((transcriptId) => {
      console.log('PLYR: Entering scrollToTranscript function');
      console.log(
        'PLYR: transcriptId=',
        transcriptId,
        'type:',
        typeof transcriptId,
        'lastScrolledTranscriptId.current=',
        lastScrolledTranscriptId.current,
        'transcriptContainerRef.current exists:',
        !!transcriptContainerRef.current
      );

      const transcriptElement = document.getElementById(`transcript-${transcriptId}`);
      console.log('PLYR: transcriptElement exists:', !!transcriptElement);

      if (transcriptId !== undefined && transcriptContainerRef.current && transcriptElement) {
        console.log('PLYR: Inside main if statement');
        console.log('PLYR: transcriptElement innerHTML:', transcriptElement.innerHTML);

        const container = transcriptContainerRef.current;
        const targetScrollTop = transcriptElement.offsetTop - container.offsetTop;

        console.log('PLYR: Scrolling to:', targetScrollTop);

        container.scrollTo({
          top: targetScrollTop,
          behavior: 'smooth',
        });

        lastScrolledTranscriptId.current = transcriptId;
        console.log('PLYR: Scroll complete');
      } else {
        console.log('PLYR: Conditions not met for scrolling');
      }
    }, []);

    useEffect(() => {
      const updateActiveTranscript = () => {
        const { currentTime } = getPlayerState ? getPlayerState() : { currentTime: 0 };
        let activeTranscript = null;
        for (let i = transcripts.length - 1; i >= 0; i--) {
          if (transcripts[i].relativeTimestamp <= currentTime) {
            activeTranscript = transcripts[i];
            break;
          }
        }

        if (activeTranscript) {
          setActiveTranscriptId(activeTranscript.relativeTimestamp);
        }
      };

      const intervalId = setInterval(updateActiveTranscript, 1000);

      return () => clearInterval(intervalId);
    }, [getPlayerState, transcripts]);

    useEffect(() => {
      if (showTranscript && activeTranscriptId !== null) {
        scrollToTranscript(activeTranscriptId);
      }
    }, [activeTranscriptId, showTranscript, scrollToTranscript]);

    // Events

    const getEventForTranscript = useCallback(
      (index) => {
        return events.find(
          (event) =>
            index >= event.index &&
            (event.endIndex && event.endIndex > event.index ? index <= event.endIndex : index === event.index)
        );
      },
      [events]
    );

    const uniqueEventTypes = useMemo(() => {
      if (!events || events.length === 0) return [];
      return Array.from(new Set(events.map((event) => event.type)));
    }, [events]);

    const handleEventTypeClick = (eventType) => {
      setSelectedEventType((prevType) => (prevType === eventType ? null : eventType));
    };

    const filteredTranscripts = useMemo(() => {
      console.log('events: ', events);
      if (!selectedEventType) return transcripts.map((t, index) => ({ ...t, originalIndex: index }));
      return transcripts
        .map((t, index) => ({ ...t, originalIndex: index }))
        .filter((_, index) => {
          return events.some(
            (event) =>
              event.type === selectedEventType &&
              index >= event.index &&
              (event.endIndex ? index <= event.endIndex : index === event.index)
          );
        });
    }, [transcripts, events, selectedEventType]);

    const eventTypeCounts = useMemo(() => {
      if (!events || events.length === 0) return {};
      return events.reduce((acc, event) => {
        acc[event.type] = (acc[event.type] || 0) + 1;
        return acc;
      }, {});
    }, [events]);

    const handleSeeAllTranscripts = () => {
      setSelectedEventType(null);
    };

    const formatTimestamp = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
      }
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
      <div className={`alert-description ${inShiftExpanded ? 'in-shift-expanded' : ''}`}>
        {showTranscript && transcripts.length > 0 && (
          <div className="alert-transcript-container">
            {uniqueEventTypes.length > 0 && (
              <div className="event-type-chips">
                {uniqueEventTypes.map((eventType) => (
                  <button
                    key={eventType}
                    className={`event-type-chip ${selectedEventType === eventType ? 'selected' : ''}`}
                    onClick={() => handleEventTypeClick(eventType)}
                  >
                    {eventType}
                    <span className="event-type-count">({eventTypeCounts[eventType]})</span>
                  </button>
                ))}
                <button
                  className={`see-all-transcripts ${selectedEventType ? 'visible' : ''}`}
                  onClick={handleSeeAllTranscripts}
                >
                  See All Transcripts
                </button>
              </div>
            )}
            <div className="transcript-box" ref={transcriptContainerRef}>
              {filteredTranscripts.map((item, index) => {
                const nextItem = index < filteredTranscripts.length - 1 ? filteredTranscripts[index + 1] : null;
                const isActive = isTranscriptActive(item, nextItem, getPlayerState?.()?.currentTime || 0);
                const preEvent = isPreEvent(item);
                const event = getEventForTranscript(item.originalIndex);
                if (item.originalIndex < 30) {
                  console.log('events: item.originalIndex: ', item.originalIndex, ' event: ', event);
                }
                const showEventPill = event && event.index === item.originalIndex;
                const showEventDot = event && item.originalIndex > event.index && item.originalIndex <= event.endIndex;
                const isFirstInEvent = event && item.originalIndex === event.index;
                const isLastInEvent = event && item.originalIndex === event.endIndex;

                return (
                  <div
                    key={item.originalIndex}
                    id={`transcript-${item.relativeTimestamp}`}
                    className={`transcript-line ${isActive ? 'active' : ''} ${preEvent ? 'pre-event' : ''} ${
                      event ? 'in-event' : ''
                    } ${isFirstInEvent ? 'first-in-event' : ''} ${isLastInEvent ? 'last-in-event' : ''}`}
                    onClick={() => !preEvent && onTranscriptClick(item.relativeTimestamp)}
                  >
                    <span className="transcript-timestamp">
                      {!preEvent ? (
                        formatTimestamp(item.relativeTimestamp)
                      ) : (
                        <Tooltip
                          title="Video recording not started yet"
                          placement="top"
                          arrow
                          classes={{ tooltip: 'custom-tooltip' }}
                        >
                          <i className="fas fa-video-slash pre-event-icon"></i>
                        </Tooltip>
                      )}
                    </span>
                    <p className="transcript-text">
                      <span className={showEventPill ? 'escalation-text' : ''}>{item.transcript}</span>
                    </p>
                    {showEventPill && <span className="event-pill">{event.type}</span>}
                    {(showEventDot || showEventPill) && (
                      <div className="event-line">{showEventDot && <span className="event-dot"></span>}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* {transcripts && transcripts.length > 0 && (
          <button className="view-transcript-button" onClick={() => setShowTranscript(!showTranscript)}>
            {showTranscript ? 'Hide Transcript' : 'View Transcript'}
          </button>
        )} */}
      </div>
    );
  }
);

export default Transcript;
