import React from 'react';
import ReportCard from './ReportCard';
import DaySeparator from './NewDashboard/ShiftReview/ShiftCardList/DaySeparator';
import '../styles/Reports/ReportCardList.css';

const ReportCardList = ({ reports, onReportClick, onDeleteReport }) => {
  const sortedReports = reports.sort((a, b) => new Date(b.incidentTime) - new Date(a.incidentTime));

  const reportsByDate = sortedReports.reduce((acc, report) => {
    const date = new Date(report.incidentTime).toDateString();
    if (!acc[date]) acc[date] = [];
    acc[date].push(report);
    return acc;
  }, {});

  return (
    <div className="report-card-list">
      {Object.entries(reportsByDate).map(([date, dateReports]) => (
        <div key={date}>
          <DaySeparator date={new Date(date)} />
          {dateReports.map((report) => (
            <ReportCard
              key={report.id || report.reportId}
              report={report}
              onClick={() => onReportClick(report)}
              onDelete={onDeleteReport}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default ReportCardList;
