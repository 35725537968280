const MAX_CACHED_SHIFTS = 200;
let shiftCache = new Map();
let totalCachedShifts = 0;

export const cacheShifts = (timePeriodKey, shifts) => {
  // Don't cache "this week"
  if (timePeriodKey === 'thisWeek') return;

  // Remove oldest entries if we exceed max cache size
  while (totalCachedShifts + shifts.length > MAX_CACHED_SHIFTS && shiftCache.size > 0) {
    const oldestKey = shiftCache.keys().next().value;
    const oldestSize = shiftCache.get(oldestKey).length;
    shiftCache.delete(oldestKey);
    totalCachedShifts -= oldestSize;
  }

  shiftCache.set(timePeriodKey, shifts);
  totalCachedShifts += shifts.length;
};

export const getCachedShifts = (timePeriodKey) => {
  return shiftCache.get(timePeriodKey);
};

export const clearShiftCache = () => {
  shiftCache.clear();
  totalCachedShifts = 0;
};
