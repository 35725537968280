import { startOfDay, subDays, isToday, isYesterday, isThisWeek, isWithinInterval, addDays } from 'date-fns';
import { fetchLatestLocationEvent } from '../api/beaverApi';

const MIN_TIME_BETWEEN_EVENTS = 60;

export const getShiftOfflineTime = async (shift) => {
  // if shift.lastSeen is n/a then we fallback to shift.lastShiftTime then to null
  const lastSeen =
    shift.lastSeen && shift.lastSeen !== 'N/A'
      ? new Date(shift.lastSeen)
      : shift.lastShiftTime
        ? new Date(shift.lastShiftTime)
        : null;
  const currentTime = new Date();

  // Fetch the latest location event
  const latestLocationEvent = await fetchLatestLocationEvent(shift.deviceId, currentTime.toISOString());
  // if latestLocationEvent is null, then no location event has been seen for the device
  const latestLocationTime = latestLocationEvent ? new Date(latestLocationEvent.enter_time) : null;
  // const latestLocationTime = null;

  // Determine the most recent time between lastSeen and latestLocationTime
  // if latestLocationTime is null, we want to use lastSeen
  // if lastSeen is null, we want to use latestLocationTime
  // if latestLocationTime is greater than lastSeen, we want to use latestLocationTime
  // if lastSeen is greater than latestLocationTime, we want to use lastSeen
  const mostRecentTime =
    latestLocationTime && (!lastSeen || latestLocationTime > lastSeen) ? latestLocationTime : lastSeen;

  if (mostRecentTime) {
    const timeDifference = currentTime.getTime() - mostRecentTime.getTime();
    const isOffline = timeDifference > 30 * 60 * 1000; // 30 minutes in milliseconds
    return { isOffline, offlineSince: mostRecentTime };
  }

  return { isOffline: false, offlineSince: null };
};

export const groupShiftsByDateRange = (shifts, currentTimePeriod = 'today') => {
  const today = startOfDay(new Date());
  const lastWeekStart = subDays(today, 7);
  const lastMonthStart = subDays(today, 30);

  return shifts.reduce(
    (acc, shift) => {
      const shiftDate = new Date(shift.startTime);
      const shiftStartDay = startOfDay(shiftDate);

      // Group shifts exclusively based on their start date
      if (isWithinInterval(shiftStartDay, { start: today, end: addDays(today, 1) })) {
        acc.today.push(shift);
      } else if (isWithinInterval(shiftStartDay, { start: lastWeekStart, end: today })) {
        acc.lastWeek.push(shift);
      } else if (isWithinInterval(shiftStartDay, { start: lastMonthStart, end: lastWeekStart })) {
        acc.lastMonth.push(shift);
      } else if (shiftStartDay < lastMonthStart) {
        acc.older.push(shift);
      }

      return acc;
    },
    { today: [], lastWeek: [], lastMonth: [], older: [] }
  );
};

export const getDaysDifference = (start, end) => {
  const startDate = new Date(start).setHours(0, 0, 0, 0);
  const endDate = new Date(end).setHours(0, 0, 0, 0);
  return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
};

export const filterShiftsByTimePeriod = (shifts, timePeriod) => {
  if (!shifts || !Array.isArray(shifts)) return {};

  const now = new Date();
  const today = new Date(now.setHours(0, 0, 0, 0));
  const lastWeek = new Date(now.setDate(now.getDate() - 7));
  const lastMonth = new Date(now.setMonth(now.getMonth() - 1));

  const filtered = {
    today: [],
    lastWeek: [],
    lastMonth: [],
    older: [],
  };

  // Filter based on time period
  shifts.forEach((shift) => {
    const shiftDate = new Date(shift.startTime);
    if (shiftDate >= today) {
      filtered.today.push(shift);
    } else if (shiftDate >= lastWeek) {
      filtered.lastWeek.push(shift);
    } else if (shiftDate >= lastMonth) {
      filtered.lastMonth.push(shift);
    } else {
      filtered.older.push(shift);
    }
  });

  return filtered;
};
