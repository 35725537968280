import React, { useState, useRef, useEffect } from 'react';
import ShiftCard from './ShiftCard/ShiftCard';
import DaySeparator from './DaySeparator';
import '../../../../styles/NewDashboard/ShiftReview/ShiftCardList/ShiftCardList.css';
import '../../../../styles/NewDashboard/ShiftReview/ShiftCardList/DaySeparator.css';
import { fetchGeofencesForOrganization } from '../../../../api/beaverApi';
import { PLIX_OVERWATCH_ACCOUNT } from '../../../../utils/utilsEvents';

const ShiftCardSkeleton = () => (
  <div className="shift-collapsed-container skeleton">
    <div className="shift-collapsed-left">
      <div className="skeleton-personnel"></div>
      <div className="shift-collapsed-time">
        <div className="skeleton-time"></div>
        <div className="skeleton-duration"></div>
        <div className="skeleton-time"></div>
      </div>
    </div>
    <div className="shift-collapsed-right">
      <div className="skeleton-escalations"></div>
    </div>
    <div className="shift-flags">
      <div className="skeleton-flag"></div>
    </div>
    <button className="shift-collapsed-expand skeleton-expand">+</button>
  </div>
);

const DaySeparatorSkeleton = () => (
  <div className="day-separator">
    <div className="day-separator-line"></div>
    <div className="day-separator-bubble skeleton-bubble">
      <div className="skeleton-text"></div>
    </div>
  </div>
);

const ShiftCardList = ({
  shifts,
  onUpdateShiftFlag,
  expandedShiftId,
  setExpandedShiftId,
  isLoading,
  retentionPeriodDays,
  isOverwatch,
  isLoadingGeofences,
  geofences,
}) => {
  const [lastExpandedShiftId, setLastExpandedShiftId] = useState(null);
  const expandedShiftRef = useRef(null);

  useEffect(() => {
    if (lastExpandedShiftId && expandedShiftRef.current) {
      expandedShiftRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [lastExpandedShiftId]);

  const handleToggle = (shiftId) => {
    setExpandedShiftId((prevId) => (prevId === shiftId ? null : shiftId));
    setLastExpandedShiftId(shiftId);
  };

  const calculateSkeletonRows = () => {
    const rowHeight = 60; // Approximate height of each skeleton row in pixels
    const separatorHeight = 40; // Approximate height of each separator in pixels
    const viewportHeight = window.innerHeight;
    const totalRows = Math.floor(viewportHeight / (rowHeight + separatorHeight));
    return totalRows;
  };

  const renderShifts = () => {
    if (isLoading || isLoadingGeofences || shifts.length === 0) {
      const skeletonRows = calculateSkeletonRows();
      const skeletons = [<DaySeparatorSkeleton key="separator-start" />]; // Add the first DaySeparatorSkeleton

      for (let i = 0; i < skeletonRows; i++) {
        skeletons.push(<ShiftCardSkeleton key={i} />);
        if ((i + 1) % 3 === 0) {
          skeletons.push(<DaySeparatorSkeleton key={`separator-${i}`} />);
        }
      }

      return <>{skeletons}</>;
    }

    const sortedShifts = shifts.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

    const shiftsByDate = sortedShifts.reduce((acc, shift) => {
      const date = new Date(shift.startTime).toDateString();
      if (!acc[date]) acc[date] = [];
      acc[date].push(shift);
      return acc;
    }, {});

    return Object.entries(shiftsByDate).map(([date, dateShifts]) => (
      <div key={date}>
        <DaySeparator date={new Date(date)} />
        {dateShifts.map((shift) => {
          if (shift.events.length > 0) {
            return (
              <div key={shift.id} ref={lastExpandedShiftId === shift.id ? expandedShiftRef : null}>
                <ShiftCard
                  shift={shift}
                  onUpdateShiftFlag={onUpdateShiftFlag}
                  isExpanded={expandedShiftId === shift.id}
                  onToggle={() => handleToggle(shift.id)}
                  geofences={geofences}
                  retentionPeriodDays={retentionPeriodDays}
                  isOverwatch={isOverwatch}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    ));
  };

  return (
    <div className="shift-card-list-container">
      {renderShifts()}
      {/* {hasMore && shifts.length > 0 && (
        <button className="load-more-button" onClick={onLoadMore}>
          Load More
        </button>
      )} */}
    </div>
  );
};

export default ShiftCardList;
