// shiftTrackingUtils.js
const STORAGE_KEY = 'openedShifts';

const getStartOfWeek = (date) => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

const getEndOfWeek = (date) => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() + (day === 0 ? 0 : 7 - day);
  return new Date(d.setDate(diff));
};

export const trackShiftOpened = (shiftId, shiftStartTime) => {
  const now = new Date();
  const twoWeeksAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);
  let openedShifts = [];

  try {
    const storedData = localStorage.getItem(STORAGE_KEY);
    openedShifts = storedData ? JSON.parse(storedData) : [];
  } catch (error) {
    console.error('Error parsing openedShifts from localStorage:', error);
  }

  // Remove entries older than two weeks based on shift start time
  openedShifts = openedShifts.filter((entry) => new Date(entry.startTime) >= twoWeeksAgo);

  // Check if the shift is already in the list
  const existingEntryIndex = openedShifts.findIndex((entry) => entry.shiftId === shiftId);

  if (existingEntryIndex !== -1) {
    // Update the entry if it exists
    openedShifts[existingEntryIndex].startTime = shiftStartTime;
    openedShifts[existingEntryIndex].lastOpened = now.toISOString();
  } else {
    // Add new entry if it's a new shift
    openedShifts.push({
      shiftId,
      startTime: shiftStartTime,
      lastOpened: now.toISOString(),
    });
  }

  localStorage.setItem(STORAGE_KEY, JSON.stringify(openedShifts));
};

export const getShiftOpeningStats = () => {
  let openedShifts = [];
  try {
    const storedData = localStorage.getItem(STORAGE_KEY);
    openedShifts = storedData ? JSON.parse(storedData) : [];
  } catch (error) {
    console.error('Error parsing openedShifts from localStorage:', error);
  }

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
  const thisWeekStart = getStartOfWeek(now);
  const lastWeekStart = new Date(thisWeekStart.getFullYear(), thisWeekStart.getMonth(), thisWeekStart.getDate() - 7);
  const lastWeekEnd = new Date(thisWeekStart.getTime() - 1);

  let daysSinceLastOpened = Infinity;
  let uniqueToday = new Set();
  let uniqueYesterday = new Set();
  let uniqueThisWeek = new Set();
  let uniqueLastWeek = new Set();

  openedShifts.forEach((entry) => {
    const entryStartTime = new Date(entry.startTime);
    const entryLastOpened = new Date(entry.lastOpened);

    // Days since last opened
    const daysDiff = Math.floor((now - entryLastOpened) / (1000 * 60 * 60 * 24));
    daysSinceLastOpened = Math.min(daysSinceLastOpened, daysDiff);

    // Categorize based on shift start time
    const entryDate = new Date(entryStartTime.getFullYear(), entryStartTime.getMonth(), entryStartTime.getDate());

    if (entryDate.getTime() === today.getTime()) {
      uniqueToday.add(entry.shiftId);
    } else if (entryDate.getTime() === yesterday.getTime()) {
      uniqueYesterday.add(entry.shiftId);
    }

    if (entryStartTime >= thisWeekStart) {
      uniqueThisWeek.add(entry.shiftId);
    } else if (entryStartTime >= lastWeekStart && entryStartTime < thisWeekStart) {
      uniqueLastWeek.add(entry.shiftId);
    }
  });

  return {
    daysSinceLastOpened: daysSinceLastOpened === Infinity ? null : daysSinceLastOpened,
    openedToday: uniqueToday.size,
    openedYesterday: uniqueYesterday.size,
    openedThisWeek: uniqueThisWeek.size,
    openedLastWeek: uniqueLastWeek.size,
  };
};
