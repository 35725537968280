// src/utils/categoryUtils.js

let categoriesData = [];

// Function to load deployed models
export const getCategories = async () => {
  try {
    if (categoriesData.length === 0) {
      const response = await fetch('/categories.json');
      const data = await response.json();
      categoriesData = data.categories;
    }
    return categoriesData;
  } catch (error) {
    console.error('Error loading deployed models:', error);
  }
};

export const getCategoryDisplay = (categoryName, localCategories = categoriesData) => {
  const category = localCategories.find((cat) => cat.name === categoryName);
  return category?.display || categoryName;
};

export const shouldDisplayCategory = (categoryName, localCategories = categoriesData) => {
  const category = localCategories.find((cat) => cat.name === categoryName);
  return !!category?.display;
};
