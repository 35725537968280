// In Reports.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportCardList from './ReportCardList';
import ReportEditor from './ReportEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { fetchReports, addOrUpdateReport, deleteReport } from '../api/beaverApi';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import '../styles/Reports/Reports.css';

const Reports = ({ userId, userRole, userEmail }) => {
  const [reports, setReports] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { devices, selectedOrg } = useDeviceFilter();

  const getEffectiveUserId = (userId, selectedOrg) => {
    return userId === PLIX_OVERWATCH_ACCOUNT
      ? selectedOrg === PLIX_OVERWATCH_ACCOUNT
        ? undefined
        : selectedOrg
      : userId;
  };

  useEffect(() => {
    const loadReports = async () => {
      try {
        const fetchedReports = await fetchReports(getEffectiveUserId(userId, selectedOrg));
        const validReports = fetchedReports.filter(
          (report) => report && typeof report.incidentTime === 'string' && report.incidentTime.trim() !== ''
        );
        const sortedReports = validReports.sort((a, b) => new Date(b.incidentTime) - new Date(a.incidentTime));
        setReports(sortedReports);
      } catch (error) {
        console.error('Failed to load reports:', error);
        setReports([]);
      }
    };

    loadReports();
  }, [userId, selectedOrg]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const timestamp = params.get('timestamp');
    const deviceId = params.get('deviceId');
    const assignedTo = params.get('assignedTo');

    if (timestamp) {
      // Parse the timestamp more robustly
      let localTimestamp;
      try {
        // Try to parse the ISO 8601 format
        const date = new Date(timestamp);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
        }
        // Convert to local time and format
        localTimestamp = date.toLocaleString('sv-SE').replace(' ', 'T');
      } catch (error) {
        console.error('Error parsing timestamp:', error);
        // Fallback to current time if parsing fails
        localTimestamp = new Date().toLocaleString('sv-SE').replace(' ', 'T');
      }

      handleOpenEditor({
        incidentTime: localTimestamp,
        deviceIds: deviceId ? [deviceId] : [],
        assignedTos: assignedTo ? [assignedTo] : [],
      });
    }
  }, [location, devices]);

  const handleOpenEditor = (report = null) => {
    setSelectedReport(report);
    setIsEditorOpen(true);
  };

  const handleCloseEditor = () => {
    setSelectedReport(null);
    setIsEditorOpen(false);
    navigate('/reports'); // Add this line to navigate to /reports
  };

  const handleSaveReport = async (savedReport) => {
    try {
      const updatedReport = await addOrUpdateReport(getEffectiveUserId(userId, selectedOrg), savedReport);

      setReports((prevReports) => {
        const newReports = savedReport.reportId
          ? prevReports.map((r) => (r.reportId === savedReport.reportId ? updatedReport : r))
          : [updatedReport, ...prevReports];
        return newReports
          .filter((report) => report && report.incidentTime) // Ensure we only have valid reports
          .sort((a, b) => new Date(b.incidentTime) - new Date(a.incidentTime));
      });

      handleCloseEditor();
    } catch (error) {
      console.error('Error saving report:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleDeleteReport = async (reportId) => {
    try {
      const result = await deleteReport(reportId, getEffectiveUserId(userId, selectedOrg));
      if (result.success) {
        setReports((prevReports) => prevReports.filter((report) => report.reportId !== reportId));
      } else {
        console.error('Failed to delete report:', result.message);
        // Optionally, show an error message to the user
      }
    } catch (error) {
      console.error('Error deleting report:', error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <div className="reports-container">
      <div className="reports-header">
        <div className="reports-header-left">
          <h1>Reports</h1>
        </div>
      </div>
      <div className="reports-body">
        <ReportCardList reports={reports} onReportClick={handleOpenEditor} onDeleteReport={handleDeleteReport} />
        <button className="fab" onClick={() => handleOpenEditor()}>
          <FontAwesomeIcon icon={faPen} className="icon" />
          <span className="text">Create Report</span>
        </button>
        {isEditorOpen && (
          <ReportEditor
            report={selectedReport}
            onClose={handleCloseEditor}
            onSave={handleSaveReport}
            userEmail={userEmail}
            devices={devices}
          />
        )}
      </div>
    </div>
  );
};

export default Reports;
