import { format } from 'date-fns';

export const formatLastUpdated = (lastUpdated) => {
  if (!lastUpdated) return '';
  const minutesSince = Math.floor((new Date() - lastUpdated) / (1000 * 60));

  if (minutesSince === 0) {
    return '0m ago';
  } else if (minutesSince < 60) {
    return `${minutesSince}m ago`;
  } else {
    const hours = Math.floor(minutesSince / 60);
    const minutes = minutesSince % 60;
    return `${hours}h ${minutes}m ago`;
  }
};
export const formatTooltipTime = (lastUpdated) => {
  if (!lastUpdated) return '';
  return format(lastUpdated, 'h:mm aa');
};
