// EventInfo.js
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventInfo/EventInfo.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import LocationInfo from './LocationInfo';
import RecordingInfo from './RecordingInfo';
import SiteInfo from './SiteInfo';
import SingleMapView from './SingleMapView';
import Transcript from '../../../../../../Transcript';

import { useState } from 'react';

const EventInfo = ({
  showVideo,
  selectedEvent,
  alertData,
  assignedTo,
  allEvents,
  getPlayerState,
  onTranscriptClick,
  eventTypes,
  shiftEndTime,
  isShiftInProgress,
  locations,
  setMarkers,
}) => {
  console.log('alertData', alertData);

  const createReportLink = () => {
    const timestamp = selectedEvent.event.timestamp || selectedEvent.event.start || selectedEvent.event.timestampStart;
    const params = new URLSearchParams({
      timestamp: new Date(timestamp).toISOString(), // Ensure consistent ISO 8601 format
      deviceId: selectedEvent.event.deviceId || '',
      assignedTo: assignedTo || '',
    });
    return `/reports?${params.toString()}`;
  };

  return (
    <div className="event-info-container">
      <Tooltip title="Create Report" placement="top" arrow>
        <Link to={createReportLink()} className="create-report-link">
          <FontAwesomeIcon icon={faPen} className="create-report-icon" />
        </Link>
      </Tooltip>
      {selectedEvent.event.type === 'escalation' ? (
        <>
          <div className="event-info-map">
            <SingleMapView location={selectedEvent.event.location} size="small" />
          </div>
          <div className="event-info-content">
            <LocationInfo
              event={selectedEvent}
              assignedTo={assignedTo}
              alertData={alertData}
              allEvents={allEvents}
              showDetails={false}
              eventTypes={eventTypes}
              shiftEndTime={shiftEndTime}
              isShiftInProgress={isShiftInProgress}
              locations={locations}
            />
            {alertData && (
              <Transcript
                alert={alertData}
                getPlayerState={getPlayerState}
                onTranscriptClick={onTranscriptClick}
                inShiftExpanded={true}
                setMarkers={setMarkers}
              />
            )}
          </div>
        </>
      ) : selectedEvent.event.type === 'recordingVideo' ? (
        <>
          <div className="event-info-content">
            <RecordingInfo event={selectedEvent.event} assignedTo={assignedTo} />
            <Transcript
              transcriptUrl={`https://plixvideostorage.blob.core.windows.net/transcripts-vtt/${selectedEvent.event.fileName?.replace('.mp4', '.vtt')}?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`}
              getPlayerState={getPlayerState}
              onTranscriptClick={onTranscriptClick}
              defaultShowTranscript={true}
              inShiftExpanded={true}
              showTimeline={false}
              setMarkers={setMarkers}
            />
          </div>
        </>
      ) : selectedEvent.event.type === 'locationEnter' ? (
        <SiteInfo
          event={selectedEvent.event}
          assignedTo={assignedTo}
          shiftEndTime={shiftEndTime}
          isShiftInProgress={isShiftInProgress}
        />
      ) : (
        <LocationInfo
          event={selectedEvent}
          assignedTo={assignedTo}
          alertData={alertData}
          allEvents={allEvents}
          showDetails={true}
          eventTypes={eventTypes}
          shiftEndTime={shiftEndTime}
          isShiftInProgress={isShiftInProgress}
          locations={locations}
        />
      )}
    </div>
  );
};

export default EventInfo;
