import React, { useState, useEffect, useRef } from 'react';
import ShiftToggle from './ShiftToggle/ShiftToggle';
import ShiftCardList from './ShiftCardList/ShiftCardList';
import '../../../styles/NewDashboard/ShiftReview/ShiftReview.css';
import { updateShiftFlagStatus } from '../../../api/beaverApi';
import { toast } from 'react-toastify';
import { cacheShifts, getCachedShifts } from '../../../utils/shiftCache';
import { PLIX_OVERWATCH_ACCOUNT } from '../../../utils/utilsEvents';

const MIN_ONLINE_TIME = 0;

const ShiftReview = ({
  selectedPersonnel,
  setSelectedPersonnel,
  userId,
  deviceAssignments,
  selectedOrg,
  isOverwatch,
  retentionPeriodDays,
  shifts,
  setShifts,
  isLoadingGeofences,
  geofences,
  isLoadingShifts,
  flaggedShiftsCount,
  setFlaggedShiftsCount,
  currentTimePeriod,
}) => {
  const [showFlaggedOnly, setShowFlaggedOnly] = useState(false);
  const [expandedShiftId, setExpandedShiftId] = useState(null);

  const updateShiftFlag = async (shiftId, isFlagged) => {
    console.log(`Updating shift ${shiftId} flag to ${isFlagged}`);

    // Update local state immediately
    setShifts((prevShifts) => prevShifts.map((shift) => (shift.id === shiftId ? { ...shift, isFlagged } : shift)));
    setFlaggedShiftsCount((prevCount) => prevCount + (isFlagged ? 1 : -1));

    // Update cache
    const cacheKey = `${currentTimePeriod.startDate.toISOString()}_${currentTimePeriod.endDate.toISOString()}`;
    const cachedData = getCachedShifts(cacheKey);
    if (cachedData) {
      const updatedCachedShifts = cachedData.map((shift) => (shift.id === shiftId ? { ...shift, isFlagged } : shift));
      cacheShifts(cacheKey, updatedCachedShifts);
    }

    try {
      const effectiveUserId = userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId;
      await updateShiftFlagStatus(shiftId, effectiveUserId, isFlagged);
      console.log(`Shift ${shiftId} flag status updated to ${isFlagged} in the database`);
    } catch (error) {
      // Revert local changes if API call fails
      console.error('Error updating shift flag status:', error);
      setShifts((prevShifts) =>
        prevShifts.map((shift) => (shift.id === shiftId ? { ...shift, isFlagged: !isFlagged } : shift))
      );
      setFlaggedShiftsCount((prevCount) => prevCount + (isFlagged ? -1 : 1));

      // Revert cache changes
      if (cachedData) {
        cacheShifts(cacheKey, cachedData);
      }

      // Show error toast
      toast.error('Failed to update shift flag status. Please try again.');
    }
  };

  const handlePersonnelChange = (personnel) => {
    setSelectedPersonnel(personnel);
  };

  const handleToggleChange = (showFlagged) => {
    setShowFlaggedOnly(showFlagged);
    setExpandedShiftId(null);
  };

  // Determine which shifts to display
  const displayedShifts = showFlaggedOnly ? shifts.filter((shift) => shift.isFlagged) : shifts;

  // Calculate the total number of displayed shifts
  const displayedShiftsCount = displayedShifts.length;

  return (
    <div className="shiftreview-container">
      <ShiftToggle
        onToggleChange={handleToggleChange}
        flaggedShiftsCount={flaggedShiftsCount}
        deviceAssignments={deviceAssignments}
        onPersonnelChange={handlePersonnelChange}
        selectedPersonnel={selectedPersonnel}
      />
      <div className="shiftreview-body">
        {!isLoadingShifts && displayedShiftsCount === 0 ? (
          <div className="no-shifts-message">{showFlaggedOnly ? 'No flagged shifts to show' : 'No shifts to show'}</div>
        ) : (
          <ShiftCardList
            shifts={displayedShifts}
            devices={selectedPersonnel}
            onUpdateShiftFlag={updateShiftFlag}
            expandedShiftId={expandedShiftId}
            setExpandedShiftId={setExpandedShiftId}
            isLoading={isLoadingShifts}
            retentionPeriodDays={retentionPeriodDays}
            isOverwatch={isOverwatch}
            isLoadingGeofences={isLoadingGeofences}
            geofences={geofences}
          />
        )}
      </div>
    </div>
  );
};

export default ShiftReview;
