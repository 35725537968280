// MetricsDisplay.js
import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons';

const categories = ['Days Since Last', 'Last Week', 'This Week', 'Yesterday', 'Today'];

function MetricsDisplay({ metrics, deviceMetrics, shiftOpeningStats, isOverwatch }) {
  const [currentCategory, setCurrentCategory] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isAutoRotating, setIsAutoRotating] = useState(true);

  const rotateMetrics = useCallback(() => {
    console.log('Rotating metrics');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentCategory((prev) => (prev + 1) % categories.length);
      setIsTransitioning(false);
    }, 500);
  }, []);

  const startRotationInterval = useCallback(() => {
    console.log('Starting new rotation interval');
    if (intervalId) {
      clearInterval(intervalId);
    }
    const newIntervalId = setInterval(() => {
      console.log('Interval triggered');
      rotateMetrics();
    }, 30000); // Changed to 10 seconds
    setIntervalId(newIntervalId);
  }, [rotateMetrics]);

  useEffect(() => {
    if (isAutoRotating) {
      console.log('Setting up initial interval');
      startRotationInterval();
    }
    return () => {
      console.log('Cleaning up interval');
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoRotating, startRotationInterval]);

  const handleClick = () => {
    console.log('Metrics clicked');
    if (isAutoRotating) {
      // Stop auto-rotation
      setIsAutoRotating(false);
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    // Rotate immediately
    rotateMetrics();
  };

  if (!isOverwatch) return null;

  const formatFraction = (numerator, denominator) => {
    return `${numerator}/${denominator}`;
  };

  const renderMetrics = () => {
    const category = categories[currentCategory];
    switch (category) {
      case 'This Week':
        return (
          <>
            <div className="metric">
              <div className="metric-value">
                {formatFraction(metrics.labeledThisWeek || 0, metrics.totalThisWeek || 0)}
              </div>
              <div className="metric-label">
                <span>Labeled</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">
                {formatFraction(metrics.correctThisWeek || 0, metrics.labeledThisWeek || 0)}
              </div>
              <div className="metric-label">
                <span>Correct</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">{shiftOpeningStats.openedThisWeek || 0}</div>
              <div className="metric-label">
                <span>Reviewed</span>
                <span>Shifts</span>
              </div>
            </div>
          </>
        );
      case 'Last Week':
        return (
          <>
            <div className="metric">
              <div className="metric-value">
                {formatFraction(metrics.labeledLastWeek || 0, metrics.totalLastWeek || 0)}
              </div>
              <div className="metric-label">
                <span>Labeled</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">
                {formatFraction(metrics.correctLastWeek || 0, metrics.labeledLastWeek || 0)}
              </div>
              <div className="metric-label">
                <span>Correct</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">{shiftOpeningStats.openedLastWeek || 0}</div>
              <div className="metric-label">
                <span>Reviewed</span>
                <span>Shifts</span>
              </div>
            </div>
          </>
        );
      case 'Yesterday':
        return (
          <>
            <div className="metric">
              <div className="metric-value">
                {formatFraction(metrics.labeledYesterday || 0, metrics.totalYesterday || 0)}
              </div>
              <div className="metric-label">
                <span>Labeled</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">
                {formatFraction(metrics.correctYesterday || 0, metrics.labeledYesterday || 0)}
              </div>
              <div className="metric-label">
                <span>Correct</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">{shiftOpeningStats.openedYesterday || 0}</div>
              <div className="metric-label">
                <span>Reviewed</span>
                <span>Shifts</span>
              </div>
            </div>
          </>
        );
      case 'Today':
        return (
          <>
            <div className="metric">
              <div className="metric-value">{formatFraction(metrics.labeledToday || 0, metrics.totalToday || 0)}</div>
              <div className="metric-label">
                <span>Labeled</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">{formatFraction(metrics.correctToday || 0, metrics.labeledToday || 0)}</div>
              <div className="metric-label">
                <span>Correct</span>
                <span>Alerts</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">{shiftOpeningStats.openedToday || 0}</div>
              <div className="metric-label">
                <span>Reviewed</span>
                <span>Shifts</span>
              </div>
            </div>
          </>
        );
      case 'Days Since Last':
        return (
          <>
            <div className="metric">
              <div className="metric-value">
                {metrics.daysSinceLabeled === null ? <FontAwesomeIcon icon={faInfinity} /> : metrics.daysSinceLabeled}
              </div>
              <div className="metric-label">
                <span>Labeled</span>
                <span>Alert</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">
                {metrics.daysSinceCorrect === null ? <FontAwesomeIcon icon={faInfinity} /> : metrics.daysSinceCorrect}
              </div>
              <div className="metric-label">
                <span>Correct</span>
                <span>Alert</span>
              </div>
            </div>
            <div className="metric">
              <div className="metric-value">
                {shiftOpeningStats.daysSinceLastOpened === null ? (
                  <FontAwesomeIcon icon={faInfinity} />
                ) : (
                  shiftOpeningStats.daysSinceLastOpened
                )}
              </div>
              <div className="metric-label">
                <span>Reviewed</span>
                <span>Shift</span>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="metrics-display" onClick={handleClick}>
      <div className={`metrics-box ${isTransitioning ? 'transitioning' : ''}`}>
        <div className="metrics-header">{categories[currentCategory]}</div>
        <div className="metrics-content">{renderMetrics()}</div>
      </div>
    </div>
  );
}

export default MetricsDisplay;
