import DateControl from './DateControl';
import ViewSelector from './ViewSelector';
import PersonnelSelector from './PersonnelSelector';
import '../../../styles/NewDashboard/DashboardControls/DashboardControls.css';

const DashboardControls = ({
  selectedView,
  setSelectedView,
  currentTimePeriod,
  setCurrentTimePeriod,
  deviceAssignments,
  selectedPersonnel,
  setSelectedPersonnel,
}) => {
  return (
    <div className="dashboard-controls-container">
      <div className="dashboard-controls-left">
        <ViewSelector selectedView={selectedView} setSelectedView={setSelectedView} />
        <DateControl currentTimePeriod={currentTimePeriod} setCurrentTimePeriod={setCurrentTimePeriod} />
        {/* </div> */}
        {/* <div className="dashboard-controls-right"> */}
        <PersonnelSelector
          deviceAssignments={deviceAssignments}
          selectedPersonnel={selectedPersonnel}
          onPersonnelChange={setSelectedPersonnel}
        />
      </div>
    </div>
  );
};

export default DashboardControls;
