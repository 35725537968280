import React, { useState } from 'react';
import { format, isToday, isYesterday, isValid } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../../styles/NewDashboard/ShiftReview/ShiftCardList/DaySeparator.css';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const DaySeparator = ({ date, onJumpTo }) => {
  const [isOpen, setIsOpen] = useState(false);

  const formatDate = (date) => {
    if (!isValid(date)) return 'Invalid Date';
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    return format(date, 'EEEE, MMMM do');
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleJumpTo = (option) => {
    onJumpTo(option);
    setIsOpen(false);
  };

  return (
    <div className="day-separator">
      <div className="day-separator-line"></div>
      {/* <div className="day-separator-bubble" onClick={toggleDropdown}> */}
      <div className="day-separator-bubble">
        <div className="day-separator-text">
          {/* {formatDate(date)} <FontAwesomeIcon icon={faChevronDown} /> */}
          {formatDate(date)}
        </div>
      </div>
      {/* {isOpen && (
        <div className="day-separator-dropdown">
          <button onClick={() => handleJumpTo('today')}>Today</button>
          <button onClick={() => handleJumpTo('yesterday')}>Yesterday</button>
          <button onClick={() => handleJumpTo('lastWeek')}>Last Week</button>
          <button onClick={() => handleJumpTo('lastMonth')}>Last Month</button>
          <button onClick={() => handleJumpTo('specificDate')}>Jump to Date</button>
        </div>
      )} */}
    </div>
  );
};

export default DaySeparator;
