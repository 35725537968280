import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMagnifyingGlass, faCompass, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { generateNarrative } from '../api/beaverApi';
import '../styles/Reports/ReportEditor.css';

const ReportEditor = ({ report, onClose, onSave, userEmail, devices }) => {
  const [editedReport, setEditedReport] = useState({
    reportId: report?.reportId || undefined,
    authorEmail: userEmail,
    personnelInvolved: report?.assignedTos?.[0] || '',
    incidentTime: report?.incidentTime || new Date().toISOString().slice(0, 16),
    narrative: report?.narrative || '',
  });
  const [isGeneratingNarrative, setIsGeneratingNarrative] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (report && report.reportId) {
      setEditedReport((prev) => {
        if (prev.reportId !== report.reportId) {
          return {
            reportId: report.reportId,
            authorEmail: userEmail,
            personnelInvolved: report.assignedTos?.[0] || prev.personnelInvolved,
            incidentTime: report.incidentTime || prev.incidentTime,
            narrative: report.narrative || prev.narrative,
          };
        }
        return prev;
      });
    }
  }, [report, userEmail]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReport((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!editedReport.personnelInvolved) {
      newErrors.personnelInvolved = 'Please select a person.';
    }
    if (!editedReport.incidentTime) {
      newErrors.incidentTime = 'Please set an incident time.';
    }
    if (!editedReport.narrative.trim()) {
      newErrors.narrative = 'Please provide a narrative.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const deviceId = devices.find((d) => d.assignedTo === editedReport.personnelInvolved)?.deviceId;

      // Convert the datetime-local value to ISO string with local timezone offset
      const localDate = new Date(editedReport.incidentTime);
      const localISOString = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();

      const reportToSave = {
        ...editedReport,
        incidentTime: localISOString,
        assignedTos: [editedReport.personnelInvolved],
        deviceIds: deviceId ? [deviceId] : [],
      };
      onSave(reportToSave);
    }
  };

  const handleInvestigate = async () => {
    if (!editedReport.personnelInvolved || !editedReport.incidentTime) {
      alert('Please select a person and incident time before investigating.');
      return;
    }
    setIsGeneratingNarrative(true);
    try {
      const narrative = await generateNarrative(editedReport.personnelInvolved, editedReport.incidentTime);
      setEditedReport((prev) => ({ ...prev, narrative }));
    } catch (error) {
      console.error('Error generating narrative:', error);
      alert('Failed to generate narrative. Please try again.');
    } finally {
      setIsGeneratingNarrative(false);
    }
  };

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    if (!chatInput.trim()) return;

    const newMessage = { type: 'user', content: chatInput };
    setChatMessages((prev) => [...prev, newMessage]);
    setChatInput('');

    const loadingMessage = { type: 'system', content: <FontAwesomeIcon icon={faCompass} spin /> };
    setChatMessages((prev) => [...prev, loadingMessage]);

    try {
      const deviceId = devices.find((d) => d.assignedTo === editedReport.personnelInvolved)?.deviceId;
      if (!deviceId) {
        throw new Error('No device ID found for the selected personnel.');
      }
      const updatedNarrative = await generateNarrative(
        [deviceId],
        editedReport.incidentTime,
        editedReport.narrative,
        chatInput
      );
      setEditedReport((prev) => ({ ...prev, narrative: updatedNarrative }));

      setChatMessages((prev) => [
        ...prev.filter((msg) => msg !== loadingMessage),
        { type: 'system', content: 'Done!' },
      ]);
    } catch (error) {
      console.error('Error editing narrative:', error);
      setChatMessages((prev) => [
        ...prev.filter((msg) => msg !== loadingMessage),
        { type: 'system', content: 'Failed to edit narrative. Please try again.' },
      ]);
    }
  };

  return (
    <div className="report-editor-overlay" onClick={onClose}>
      <div className="report-editor" onClick={(e) => e.stopPropagation()}>
        <div className="author-note">Report written by {userEmail}</div>
        <div className="report-content">
          <div className="input-row">
            <select
              id="personnelInvolved"
              name="personnelInvolved"
              value={editedReport.personnelInvolved}
              onChange={handleInputChange}
              required
              className="rounded-input"
            >
              <option value="">Who is this report for?</option>
              {devices.map((device) => (
                <option key={device.deviceId} value={device.assignedTo}>
                  {device.assignedTo}
                </option>
              ))}
            </select>
            <input
              type="datetime-local"
              id="incidentTime"
              name="incidentTime"
              value={editedReport.incidentTime}
              onChange={handleInputChange}
              required
              className="rounded-input"
            />
            <button
              type="button"
              onClick={handleInvestigate}
              className="investigate-button"
              disabled={isGeneratingNarrative}
            >
              {isGeneratingNarrative ? (
                <FontAwesomeIcon icon={faCompass} spin />
              ) : (
                <>
                  <FontAwesomeIcon icon={faMagnifyingGlass} /> Investigate
                </>
              )}
            </button>
          </div>
          <div className="narrative-chat-container">
            <textarea
              id="narrative"
              name="narrative"
              value={editedReport.narrative}
              onChange={handleInputChange}
              required
              placeholder="Describe incident here..."
              className="report-textarea"
            ></textarea>
            <div className="chat-area">
              <div className="chat-messages">
                {chatMessages.map((msg, index) => (
                  <div key={index} className={`chat-message ${msg.type}`}>
                    {msg.content}
                  </div>
                ))}
              </div>
              <form onSubmit={handleChatSubmit} className="chat-input-form">
                <input
                  type="text"
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  placeholder="Edit narrative..."
                  className="chat-input"
                />
                <button type="submit" className="chat-submit-button">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </form>
            </div>
          </div>
          <div className="button-row">
            <button type="button" onClick={onClose} className="cancel-button">
              Cancel
            </button>
            <div className="right-group">
              <div className="errors-container">
                {Object.values(errors)
                  .filter((error) => error.length > 1)
                  .map((error, index) => (
                    <div key={index} className="error-message">
                      {error}
                    </div>
                  ))}
              </div>
              <button type="button" onClick={handleSubmit} className="save-button">
                <FontAwesomeIcon icon={faCheck} /> Save Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportEditor;
