import { useState, useEffect } from 'react';
import VideoView from './VideoView';
import MapView from './MapView';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/EventDetail.css';

const EventDetail = ({
  showVideo,
  event,
  events,
  onShowVideo,
  alertData,
  locations,
  videoPlayerRef,
  onPlayerUpdate,
  geofences,
  retentionPeriodDays,
  markers,
}) => {
  const [focusLocation, setFocusLocation] = useState(null);

  useEffect(() => {
    if (event && event.latitude && event.longitude) {
      setFocusLocation({ latitude: event.latitude, longitude: event.longitude });
    } else {
      setFocusLocation(null);
    }
  }, [event]);

  return (
    <div className="event-detail-container">
      {event.type === 'escalation' || event.type === 'recordingVideo' ? (
        <VideoView
          event={event}
          alertData={alertData}
          videoPlayerRef={videoPlayerRef}
          onPlayerUpdate={onPlayerUpdate}
          retentionPeriodDays={retentionPeriodDays}
          markers={markers}
        />
      ) : (
        <MapView
          locations={locations}
          size="large"
          event={event}
          events={events}
          geofences={geofences}
          focusLocation={focusLocation}
        />
      )}
    </div>
  );
};

export default EventDetail;
