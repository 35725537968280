import React from 'react';
import '../../../../styles/NewDashboard/Metrics/MetricsCard/MetricsCard.css';

const MetricCardSkeleton = () => {
  return (
    <div className="metrics-card skeleton">
      <div className="metrics-card-header">
        <div className="skeleton-title"></div>
      </div>
      <div className="metrics-card-body">
        <div className="skeleton-value"></div>
      </div>
      <div className="metrics-card-footer">
        <div className="skeleton-description"></div>
      </div>
    </div>
  );
};

export default MetricCardSkeleton;
