import '../../../styles/NewDashboard/DashboardControls/ViewSelector.css';

const ViewSelector = ({ selectedView, setSelectedView }) => {
  return (
    <div className="view-selector-container">
      <div className="view-selector-controls">
        <button
          className={`view-toggle-btn ${selectedView === 'shift' ? 'active' : ''}`}
          onClick={() => setSelectedView('shift')}
        >
          <i className="icon-clock"></i> Activity
        </button>
        <button
          className={`view-toggle-btn ${selectedView === 'metrics' ? 'active' : ''}`}
          onClick={() => setSelectedView('metrics')}
        >
          <i className="icon-stats"></i> Statistics
        </button>
      </div>
    </div>
  );
};

export default ViewSelector;
