import React, { useEffect, useState } from 'react';
import ShiftCollapsed from './ShiftCollapsed/ShiftCollapsed';
import ShiftExpanded from './ShiftExpanded/ShiftExpanded';
// import { fetchBatteryAtTimestamp } from '../../../../../utils/dashboardTimescaleQueries';
import '../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCard.css';
import { getShiftOfflineTime } from '../../../../../utils/shiftUtils';
import { formatDuration } from '../../../../../utils/timeUtils';
import { trackShiftOpened } from '../../../../../utils/shiftTrackingUtils';

const ShiftCard = ({ shift, onUpdateShiftFlag, isExpanded, onToggle, geofences, retentionPeriodDays, isOverwatch }) => {
  const [batteryLevels, setBatteryLevels] = useState({});
  const [offlineStatus, setOfflineStatus] = useState({ isOffline: false, offlineSince: null });
  // console.log("geofences in shiftcard", geofences)
  // Only for demo purposes
  const queryDeviceId = shift.deviceIdDemo || shift.deviceId;

  const fetchBatteryLevels = async (events, deviceId) => {
    if (!events || events.length === 0) {
      return {};
    }

    const eventsToFetch = [];

    // Check if the first event is locationEnter and the second is start
    if (events[0].type === 'locationEnter' && events[1] && events[1].type === 'start') {
      eventsToFetch.push(events[1]); // Fetch battery for the start event
    } else {
      eventsToFetch.push(events[0]); // Fetch battery for the first event
    }

    // Always fetch the last event if it's an 'end' event
    if (events.length > 1 && events[events.length - 1].type === 'end') {
      eventsToFetch.push(events[events.length - 1]);
    }

    const levels = await Promise.all(
      eventsToFetch.map(async (event) => {
        if (!event || !event.timestamp) {
          return null;
        }
        try {
          // const level = await fetchBatteryAtTimestamp(deviceId, event.timestamp);
          return { timestamp: event.timestamp, level: event.batteryLevel };
        } catch (err) {
          console.error('Failed to fetch battery level:', err);
          return { timestamp: event.timestamp, level: null };
        }
      })
    );

    return levels.reduce((acc, item) => {
      if (item && item.timestamp) {
        acc[item.timestamp] = item.level;
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    const updateShift = async () => {
      const startTime = performance.now();
      const updatedShift = shift;
      const batteryLevels = await fetchBatteryLevels(updatedShift.events, queryDeviceId);
      console.log('batteryLevels: ', batteryLevels);
      setBatteryLevels(batteryLevels);
      const endTime = performance.now();
      console.log(`Time taken for updateShift: ${endTime - startTime} milliseconds`);
    };

    updateShift();
  }, [shift, queryDeviceId]);

  useEffect(() => {
    if (isExpanded && isOverwatch) {
      trackShiftOpened(shift.id, shift.startTime);
    }
  }, [isExpanded, shift.id]);

  // In this useEffect fetchOfflineStatus()
  // if shift is in progress, we want to calculate if the device isn't seen for more than 30 minutes
  // and set isOffline to true if it is
  // if not in progress then we don't care
  useEffect(() => {
    const fetchOfflineStatus = async () => {
      if (shift.inProgress) {
        try {
          const status = await getShiftOfflineTime(shift);
          // if (shift.id === '951b7353-83c1-4d64-befc-4fe015f5ac54') {
          //   console.log('status for 951b7353-83c1-4d64-befc-4fe015f5ac54', status);
          // }
          setOfflineStatus(status);
        } catch (error) {
          console.error('Error fetching offline status:', error);
        }
      }
    };

    fetchOfflineStatus();
  }, [shift]);

  const handleToggle = () => {
    onToggle(shift.id);
  };

  const handleFlagShift = (e) => {
    const newFlaggedState = !shift.isFlagged;
    onUpdateShiftFlag(shift.id, newFlaggedState);
  };

  const calculateTimeRatio = (onlineTime, offlineTime) => {
    const total = onlineTime + offlineTime;
    return {
      onlineRatio: (onlineTime / total) * 100,
      offlineRatio: (offlineTime / total) * 100,
    };
  };

  const timeRatio = calculateTimeRatio(shift.calculatedOnlineTime, shift.calculatedOfflineTime);

  const calculateDuration = () => {
    console.log('Shift: ', shift);
    const { startTime, endTime, inProgress, lastSeen } = shift;
    const start = new Date(startTime);
    let end;
    let isLastSeen = false;

    if (inProgress) {
      if (offlineStatus.isOffline && offlineStatus.offlineSince) {
        end = new Date(offlineStatus.offlineSince);
        isLastSeen = true;
      } else {
        end = lastSeen && lastSeen !== 'N/A' ? new Date(lastSeen) : new Date();
      }
    } else {
      end = new Date(endTime);
    }

    if (!isValidDate(start) || !isValidDate(end)) {
      return { formattedDuration: '0s', isLastSeen: false };
    }

    if (end < start) {
      end = start;
    }

    const durationMs = Math.max(0, end - start);
    console.log('Last seen: ', new Date(lastSeen));
    console.log('Duration in ShiftCard: ', durationMs / 1000 / 60);
    const formattedDuration = formatDuration(durationMs);

    return { formattedDuration, isLastSeen };
  };

  const { formattedDuration, isLastSeen } = calculateDuration();

  return (
    <div className="shift-card-container">
      {isExpanded ? (
        <ShiftExpanded
          shift={shift}
          batteryLevels={batteryLevels}
          onCollapse={handleToggle}
          onFlagShift={handleFlagShift}
          timeRatio={timeRatio}
          deviceId={queryDeviceId}
          isOffline={offlineStatus.isOffline}
          offlineSince={offlineStatus.offlineSince}
          formattedDuration={formattedDuration}
          geofences={geofences}
          retentionPeriodDays={retentionPeriodDays}
          isOverwatch={isOverwatch}
        />
      ) : (
        <ShiftCollapsed
          shift={shift}
          batteryLevels={batteryLevels}
          onExpand={handleToggle}
          onFlagShift={handleFlagShift}
          timeRatio={timeRatio}
          deviceId={queryDeviceId}
          isOffline={offlineStatus.isOffline}
          offlineSince={offlineStatus.offlineSince}
          formattedDuration={formattedDuration}
          retentionPeriodDays={retentionPeriodDays}
          isOverwatch={isOverwatch}
        />
      )}
    </div>
  );
};

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

export default ShiftCard;
