import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCollapsed.css';
import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagOutlined } from '@fortawesome/free-regular-svg-icons';
import { faBatteryQuarter, faVideo, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { getDaysDifference } from '../../../../../../utils/shiftUtils';
import { formatDuration } from '../../../../../../utils/timeUtils';
import React from 'react';
import { Tooltip } from '@mui/material';

const ShiftCollapsed = ({
  shift,
  batteryLevels,
  onExpand,
  onFlagShift,
  timeRatio,
  isOffline,
  offlineSince,
  formattedDuration,
  isOverwatch,
}) => {
  const { assignedTo, deviceId, startTime, endTime, events, duration, inProgress } = shift;
  const escalationCount = events.filter((event) => event.type === 'escalation').length;
  const handleFlagShift = (e) => {
    e.stopPropagation(); // Stop the event from propagating to parent elements
    const currentFlagStatus = Boolean(shift.isFlagged);
    const newFlagStatus = !currentFlagStatus;
    console.log(`Toggling flag for shift ${shift.id} from ${currentFlagStatus} to ${newFlagStatus}`);
    onFlagShift(shift.id, newFlagStatus);
  };

  const [isHovered, setIsHovered] = useState(false);

  const onlineTime = formatDuration(shift.calculatedOnlineTime * 1000);
  const offlineTime = formatDuration(shift.calculatedOfflineTime * 1000);

  // const timeRatioTooltip = (
  //   <div>
  //     <div>Online: {onlineTime}</div>
  //     <div>Offline: {offlineTime}</div>
  //   </div>
  // );
  const daysDifference = endTime ? getDaysDifference(startTime, endTime) : 0;

  const formatDecimalHours = (seconds) => {
    const hours = seconds / 3600;
    return Math.trunc(hours * 10) / 10; // Truncate to 1 decimal place
  };

  // const onlineTimeDecimal = formatDecimalHours(shift.calculatedOnlineTime);
  // const offlineTimeDecimal = formatDecimalHours(shift.calculatedOfflineTime);

  const timeRatioTooltip = (
    <div className="custom-tooltip-time">
      <div className="tooltip-row">
        <span className="tooltip-dot online"></span>
        <span className="tooltip-label">Active</span>
        <span className="tooltip-value">{onlineTime}</span>
      </div>
      <div className="tooltip-row">
        <span className="tooltip-dot offline"></span>
        <span className="tooltip-label">Inactive</span>
        <span className="tooltip-value">{offlineTime}</span>
      </div>
    </div>
  );

  const offlineSinceText = offlineSince
    ? `Device was in shift but lost connection ${formatDistanceToNow(new Date(offlineSince))} ago`
    : 'Device was in shift but lost connection';

  const renderRecordingStatus = () => {
    if (shift.isRecording) {
      return (
        <Tooltip title="Recording in progress." placement="top">
          <span className="recording-status">
            <FontAwesomeIcon icon={faVideo} className="recording-icon blinking" />
            <span className="recording-text">Recording</span>
          </span>
        </Tooltip>
      );
    } else if (shift.totalRecordingDuration > 0) {
      return (
        <span className="recording-duration-badge">
          <FontAwesomeIcon icon={faVideo} className="recording-icon" />
          <span className="recording-duration">{formatDuration(shift.totalRecordingDuration)}</span>
        </span>
      );
    }
    return null;
  };

  const handleAssignedToClick = (e) => {
    e.stopPropagation(); // Prevent the click from collapsing the expanded view
    if (isOverwatch) {
      const grafanaUrl = `http://nginx-test.tail24705.ts.net:3000/d/adoh9r7dkkl4wf/device-status?orgId=1&var-device1=${deviceId}&from=now-7d&to=now`;
      window.open(grafanaUrl, '_blank');
    }
  };

  const renderAssignedTo = () => {
    const content = (
      <div
        className="shift-collapsed-personnel"
        onClick={handleAssignedToClick}
        style={isOverwatch ? { cursor: 'pointer' } : {}}
      >
        {assignedTo === '' ? deviceId : assignedTo}
      </div>
    );

    if (isOverwatch) {
      return (
        <Tooltip title={deviceId} placement="top-start" classes={{ tooltip: 'custom-tooltip-wrapper' }}>
          {content}
        </Tooltip>
      );
    }
    return content;
  };

  return (
    <div className="shift-collapsed-container" onClick={onExpand}>
      <div className="shift-collapsed-left">
        {renderAssignedTo()}
        {/* <div className="shift-collapsed-date">
          <span className="date-badge">{new Date(startTime).toLocaleDateString()}</span>
        </div> */}
        <div className="shift-collapsed-time">
          <div className="start-time-container">
            <span className="time-badge">
              {new Date(startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </span>
            <span className="online-time-decimal">{new Date(startTime).toLocaleDateString()}</span>
          </div>
          <div className="duration-container">
            <span className="online-time-decimal">{formattedDuration}</span>
            <hr className="duration-divider"></hr>
          </div>
          <div className="end-time-container">
            {inProgress ? (
              <>
                <span
                  className={`time-badge ${isOffline ? 'offline' : 'in-progress'}`}
                  data-offline-since={offlineSinceText}
                >
                  {isOffline ? 'Connection Lost' : 'In Progress'}
                </span>
                <span className="online-time-decimal">{new Date().toLocaleDateString()}</span>
              </>
            ) : (
              <>
                <span className="time-badge">
                  {new Date(endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  {daysDifference > 0 && <sup>+{daysDifference}</sup>}
                </span>
                <span className="online-time-decimal">{new Date(endTime).toLocaleDateString()}</span>
              </>
            )}
          </div>
        </div>
        {!inProgress && (
          <>
            {/* <span className="duration-badge">{formatDuration(duration)}</span> */}
            <div className="online-time-container">
              <span className="online-time-decimal">{onlineTime} active</span>
              <Tooltip title={timeRatioTooltip} classes={{ tooltip: 'custom-tooltip-wrapper' }}>
                <div className="time-ratio-bar">
                  <div
                    className="online-time"
                    style={{
                      width: `${timeRatio.onlineRatio}%`,
                    }}
                  ></div>
                  {shift.calculatedOfflineTime > 0 && (
                    <div
                      className="offline-time"
                      style={{
                        width: `${timeRatio.offlineRatio}%`,
                      }}
                    ></div>
                  )}
                </div>
              </Tooltip>
            </div>
          </>
        )}
        <div className="recording-duration-container">{renderRecordingStatus()}</div>
      </div>
      <div className="shift-collapsed-right">
        <div className="shift-collapsed-escalations">
          {escalationCount > 0 && (
            <span className="escalation-badge">
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} className="escalation-icon" />
                <span className="escalation-count">{escalationCount}</span>
              </>
            </span>
          )}
        </div>
      </div>
      <div className="shift-flags" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {shift.isFlagged ? (
          <FontAwesomeIcon icon={faFlagSolid} className="flag-icon filled" onClick={handleFlagShift} />
        ) : (
          <FontAwesomeIcon
            icon={isHovered ? faFlagSolid : faFlagOutlined}
            className={`flag-icon ${isHovered ? 'filled' : 'outlined'}`}
            onClick={handleFlagShift}
          />
        )}
      </div>
      <button className="shift-collapsed-expand">+</button>
    </div>
  );
};

export default ShiftCollapsed;
