import React, { useRef, useCallback, useState, useEffect } from 'react';
import { format } from 'date-fns';
import '../styles/AlertPopup.css';
import VideoPlayer from './VideoPlayer';
import { stringToColor } from '../utils/utilsUI';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const VideoPopup = React.memo(({ video }) => {
  const videoPlayerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatVideoTimestamp = useCallback((timestamp) => {
    return format(new Date(timestamp), "MMM d, yyyy 'at' h:mm a");
  }, []);

  const backgroundColor = stringToColor(video.AssignedTo || 'Unknown');

  const renderVideoContent = () => {
    if (loading) {
      return (
        <div className="video-placeholder">
          <AccessTimeIcon className="placeholder-icon" />
          <p>Loading video...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="video-placeholder">
          <ErrorOutlineIcon className="placeholder-icon" />
          <p>{error}</p>
        </div>
      );
    }

    if (video && video.generatedEmbeddings && !video.TranscriptVTTPath) {
      return (
        <div className="video-placeholder">
          <AccessTimeIcon className="placeholder-icon" />
          <p>Video currently processing</p>
        </div>
      );
    }

    if (video.FileName && video.BlobSAS) {
      return (
        <VideoPlayer
          ref={videoPlayerRef}
          videoFileName={video.FileName}
          token={video.BlobSAS}
          transcriptUrl={video.TranscriptVTTPath}
          markers={[]} // Pass an empty array if there are no markers
        />
      );
    }

    return (
      <div className="video-placeholder">
        <AccessTimeIcon className="placeholder-icon" />
        <p>Video currently unavailable</p>
      </div>
    );
  };

  return (
    <div className="alert-popup video-popup">
      <div className="alert-header">
        <div className="alert-info">
          <span className="alert-assigned-to" style={{ backgroundColor, color: '#333' }}>
            {video.AssignedTo || 'Unknown'}
          </span>
          <p className="alert-timestamp">{formatVideoTimestamp(video.TimeRecorded || video.TimeUploaded)}</p>
        </div>
      </div>
      <div className="alert-video">{renderVideoContent()}</div>
    </div>
  );
});

export default VideoPopup;
