import React from 'react';
import '../../../styles/NewDashboard/PersonnelStats/PersonnelStats.css';

const PersonnelStatsSkeleton = () => {
  const calculateSkeletonRows = () => {
    const rowHeight = 48; // Height of each row in pixels
    const headerHeight = 52; // Height of header in pixels
    const containerPadding = 40; // Total vertical padding
    const viewportHeight = window.innerHeight;
    const availableHeight = viewportHeight - headerHeight - containerPadding;
    return Math.floor(availableHeight / rowHeight);
  };

  return (
    <div className="personnel-stats-container">
      <div className="stats-table">
        <div className="table-header">
          <span>
            <div className="header-main">Name</div>
            <div className="header-subtext">&nbsp;</div>
          </span>
          <span>
            <div className="header-main">Hours In Shift</div>
            <div className="header-subtext">daily average</div>
          </span>
          <span>
            <div className="header-main">Activation Rate</div>
            <div className="header-subtext">across all shifts</div>
          </span>
          <span>
            <div className="header-main">Charged Shifts</div>
            <div className="header-subtext">percentage</div>
          </span>
          <span>
            <div className="header-main">Leaderboard Score</div>
            <div className="header-subtext">&nbsp;</div>
          </span>
        </div>
        {Array.from({ length: calculateSkeletonRows() }).map((_, index) => (
          <div key={index} className="table-row skeleton">
            <span className="skeleton-cell personnel"></span>
            <span className="skeleton-cell hours"></span>
            <span className="skeleton-cell rate"></span>
            <span className="skeleton-cell battery"></span>
            <span className="skeleton-cell ranking"></span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonnelStatsSkeleton;
