import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../styles/Reports/ReportCard.css';

const ReportCard = ({ report, onClick, onDelete }) => {
  if (!report) {
    return null;
  }

  const { authorEmail, assignedTo, incidentTime } = report;

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(report.reportId);
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <div className="report-card" onClick={onClick}>
      <div className="report-card-left">
        <div className="report-assigned-to">{assignedTo || 'Unassigned'}</div>
        <div className="report-timestamp">
          <span className="report-time">{formatTime(incidentTime)}</span>
          <span className="report-date">{formatDate(incidentTime)}</span>
        </div>
        <div className="report-author">{authorEmail}</div>
      </div>
      <div className="report-card-right">
        <button className="delete-button" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
};

export default ReportCard;
