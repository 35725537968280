import React, { useState, useEffect, useRef } from 'react';
import '../../../../styles/NewDashboard/ShiftReview/ShiftToggle/ShiftToggle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';

const ShiftToggle = ({
  onToggleChange,
  flaggedShiftsCount,
  deviceAssignments,
  onPersonnelChange,
  selectedPersonnel,
}) => {
  const [selectedToggle, setSelectedToggle] = useState('all');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggle = (toggle) => {
    setSelectedToggle(toggle);
    onToggleChange(toggle === 'flagged');
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePersonnelChange = (deviceId) => {
    const updatedPersonnel = selectedPersonnel.includes(deviceId)
      ? selectedPersonnel.filter((id) => id !== deviceId)
      : [...selectedPersonnel, deviceId];
    onPersonnelChange(updatedPersonnel);
  };

  const handleSelectAll = () => {
    onPersonnelChange(Object.keys(deviceAssignments));
  };

  const handleDeselectAll = () => {
    onPersonnelChange([]);
  };

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="shift-toggle-container">
      <div className="shift-toggle-buttons">
        <button
          className={`shift-toggle-button ${selectedToggle === 'all' ? 'selected' : ''}`}
          onClick={() => handleToggle('all')}
        >
          All Shifts
        </button>
        <button
          className={`shift-toggle-button ${selectedToggle === 'flagged' ? 'selected' : ''}`}
          onClick={() => handleToggle('flagged')}
        >
          Flagged Shifts ({flaggedShiftsCount})
        </button>
      </div>
      {/* <div className="shift-controls">
        <button className="shift-controls-button" onClick={toggleDropdown} ref={buttonRef}>
          Users <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {isDropdownOpen && (
          <div className="shift-controls-dropdown" ref={dropdownRef}>
            <div className="dropdown-actions">
              <button className="dropdown-action-button" onClick={handleSelectAll}>
                Select All
              </button>
              <button className="dropdown-action-button" onClick={handleDeselectAll}>
                Deselect All
              </button>
            </div>
            {Object.entries(deviceAssignments).map(([deviceId, name]) => (
              <button
                key={deviceId}
                className={`dropdown-item ${selectedPersonnel.includes(deviceId) ? 'selected' : ''}`}
                onClick={() => handlePersonnelChange(deviceId)}
              >
                <span className="dropdown-item-check">
                  {selectedPersonnel.includes(deviceId) && <FontAwesomeIcon icon={faCheck} />}
                </span>
                <span className="dropdown-item-name">{name}</span>
              </button>
            ))}
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ShiftToggle;
