import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faExclamationTriangle, faVideo, faBell } from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/NewDashboard/Metrics/MetricsCard/MetricsCard.css';

const MetricsCard = ({ title, value, description, trend }) => {
  const getIcon = (title) => {
    switch (title) {
      case 'Activation Rate':
        return faEye;
      case 'Alerts':
        return faBell;
      case 'Recording Time':
        return faVideo;
      default:
        return null;
    }
  };

  return (
    <div className="metrics-card">
      <div className="metrics-card-header">
        <FontAwesomeIcon icon={getIcon(title)} className="metrics-icon" />
        &nbsp;
        <h3>{title || 'Metric Title'}</h3>
      </div>
      <div className="metrics-card-body">
        <div className="metrics-value">{value !== null && value !== undefined ? value : '--'}</div>
        {trend && (
          <div className={`metrics-trend ${trend > 0 ? 'positive' : trend < 0 ? 'negative' : ''}`}>
            {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
          </div>
        )}
      </div>
      <div className="metrics-card-footer">
        <p>{description || 'Metric description'}</p>
      </div>
    </div>
  );
};

export default MetricsCard;
